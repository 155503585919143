import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

const noop = (): any => undefined;

/**
 * A common logger that is used by the app.
 */
@Injectable()
export class Logger {

    private static invokeConsoleMethod(type: string, ...args: any[]): void {
        // Don't log in production
        if (!environment.production) {
            // eslint-disable-next-line no-console
            const logFn: Function = console[type] || console.log || noop;
            logFn.apply(console, ...args);
        }
    }

    info(...args: Array<any>): void {
        Logger.invokeConsoleMethod('info', args);
    }

    warn(...args: Array<any>): void {
        Logger.invokeConsoleMethod('warn', args);
    }

    error(...args: Array<any>): void {
        Logger.invokeConsoleMethod('error', args);
    }

    debug(...args: Array<any>): void {
        Logger.invokeConsoleMethod('debug', args);
    }
}
