import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '../../shared/logger';
import { ErrorMessages } from '../../shared/error/error-messages';
import { ParteDiario, TypeEvent } from '../models';
import { QueryBuilder } from '../query';

import { AuthService } from './auth.service';
import { PermissionsService } from '../../shared/services/permissions.service';
import { ParteDiarioService } from './parte-diario.service';
import { Timestamp } from 'firebase/firestore';
import { PesadaService } from './pesada.service';
import { first } from 'rxjs/operators';
import { ReservasService } from './reservas.service';

@Injectable()
export class EventsPesadas extends RestService<TypeEvent> {

    providers: [
        { provide: 'RestService', useClass: EventsPesadas } // o EventsPesadas dependiendo del caso
      ]

    user: any;
    isMaquinista: boolean;
    parteDiario: ParteDiario;

    constructor(
        protected errorMessages: ErrorMessages,
        protected afAuth: AngularFireAuth,
        protected store: AngularFirestore,
        protected logger: Logger,
        private authService: AuthService,
        private permissionsService: PermissionsService,
        private parteDiarioService: ParteDiarioService,
        private pesadaService: PesadaService,
        private reservasService: ReservasService 
    ) {
        super(errorMessages, afAuth, store, logger, 'events_pesadas');
        this.authService.getUser()
            .subscribe(
                async (user) => {
                    this.user = user
                    this.isMaquinista = await this.permissionsService.isMaquinista();
                }    
            );
            this.parteDiarioService.getParteDiarioActivo(this.user.uid).subscribe((parteDiario)=>{
                this.parteDiario = parteDiario as ParteDiario;
            })
    }

    getParentById(id_parent: string) {
        let builder = QueryBuilder.new();
        builder.orderBy('fecha', 'desc');
        builder.where('id_parent', '==', id_parent);
        builder.limit(100);
        let options = builder.build();
        return this.allWith(options)
    }

    async setDoc(doc: TypeEvent):Promise<TypeEvent> {
        doc.origen = 'pesadas';
        doc.fecha = Timestamp.fromDate(new Date());
        /* si no tiene reserva y el parent si, se cargan los datos de la reserva en el evento*/
        if(!doc.reserva){
            const parent = await this.pesadaService.find(doc.id_parent).pipe(first()).toPromise();
            if(parent?.reserva){
                const reserva = await this.reservasService.getReservaByCode(parent?.reserva).pipe(first()).toPromise();
                if(reserva){
                    doc.reserva = reserva.code;
                    doc.reserva_nombre_producto = reserva.nombre_producto;
                    doc.reserva_empresa = reserva.empresa;
                }
            }
        }
     
        doc.user = {
            uid: this.user.uid,
            displayName: this.user.displayName,
            email: this.user.email,
            photoURL: this.user.photoURL,
            maquinista: this.isMaquinista
        }

        // Parte diario
        if (this.parteDiario) {
            if (this.parteDiario.id_equipo)
                doc.id_equipo = this.parteDiario.id_equipo;
            if (this.parteDiario.equipo)
                doc.equipo = this.parteDiario.equipo;
            doc.user.parteDiario = this.parteDiario;
        }

        return await this.add(doc).toPromise();
    }

}
