import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '../../shared/logger';
import { ErrorMessages } from '../../shared/error/error-messages';
import { Reserva } from '../models';
import { QueryBuilder } from '../query';
import { map } from 'rxjs/operators';

@Injectable()
export class ReservasService extends RestService<Reserva> {

    constructor(
        protected errorMessages: ErrorMessages,
        protected afAuth: AngularFireAuth,
        protected store: AngularFirestore,
        protected logger: Logger
    ) {
        super(errorMessages, afAuth, store, logger, 'reservas');
    }

    getReservaByCode(code: string) {
        const builder = QueryBuilder.new();
        builder.where('code', '==', code)
        return this.allWith(builder.build()).pipe(
            map((res:any)=>{
               if (res && Array.isArray(res)) //if res and is array
                  return res[0]   //return the first element
               return res;      //else resut res
            }
        ));
    }

}
