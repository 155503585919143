import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';
import { Platform } from '@ionic/angular';

@Injectable()
export class CrashlyticsService {

  isCap:boolean = false;
  msgWebPlatform:string = `Crashlytics is not supported in Web version`;

  constructor(
    protected platform: Platform,
    protected router: Router
  ) {
    if (this.platform.is('capacitor')) {
      this.isCap = true;
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationStart) {
          this.setPageUrl(val.url);
        }
      });
    }
  }

  async setUserId(id: string) {
    if (this.isCap) {
      await FirebaseCrashlytics.setUserId({ userId: id });
    } else {
      console.warn(this.msgWebPlatform);
    }
  }

  async setPageUrl(value: string) {
    if (this.isCap)
      await FirebaseCrashlytics.setContext({
        key: 'page',
        value: value,
        type: 'string'
      });
    else
      console.warn(this.msgWebPlatform);
  }

}
