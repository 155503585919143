import { Injectable } from '@angular/core';
import { Anuncio, EventList, ParteDiario, Pesada, TypeEvent, TypeOperator } from '../models';

@Injectable()
export class ModulosService {

  isEventValid(evento: EventList, contenedor: Anuncio & Pesada & ParteDiario, eventosContenedor: TypeEvent[]) {
    let showLog: boolean = false;
    try {
      let log: string = '';
      let condition_string: string = 'true';
      if (evento.conditions && contenedor && contenedor.tipo_operativa && evento.conditions[contenedor.tipo_operativa]) {
        condition_string = '(';
        evento.conditions[contenedor.tipo_operativa].forEach((condition, index) => {
          if (condition.type == TypeOperator.CONDITION) {
            if (evento.conditions[contenedor.tipo_operativa][index - 1] && evento.conditions[contenedor.tipo_operativa][index - 1].type == TypeOperator.CONDITION)
              condition_string += '&&';
            condition_string += this.getEventCount(eventosContenedor, condition.event_id, condition.after_event_id).toString() + condition.operator.toString() + condition.count.toString();
            log += '\nAFTER_EVENT:' + (condition.after_event_id ? condition.after_event_id : '-');
            log += '\nVALUE:' + condition.event_id + '=' + this.getEventCount(eventosContenedor, condition.event_id, condition.after_event_id);
            log += '\nCONDITION:' + condition.event_id + condition.operator + condition.count;
          }
          if (condition.type == TypeOperator.LOGICAL) {
            condition_string += ')';
            condition_string += condition.operator;
            condition_string += '(';
            log += '\n' + condition.operator;
          }
        })
        condition_string += ')';
        if (showLog) {
          console.log('EVENTO:' + evento.id)
          console.log(log + '\n\n');
          console.log('EVAL:' + condition_string + ' = ' + eval(condition_string));
          console.log('------------------------');
        }
      }
      return eval(condition_string);
    } catch (error) {
      throw new Error('Error: ' + evento + ' - ' + error);
    }
  }

  getEventCount(eventosContenedor: TypeEvent[], evento_id: string, after_evento_id?: string): number {
    let array: Array<TypeEvent> = eventosContenedor;
    if (after_evento_id) {
      var index = array.map((ev: TypeEvent) => { return ev.event.id }).indexOf(after_evento_id);
      if (index > -1) {
        /* sub array ordenado por fecha DESC desde after_evento_id, 
           para poder contar eventos despues del ultimo evento en particular
        */
        array = array.slice(0, index);
      }
    }
    return array.filter((ev: TypeEvent) => { return ev.event.id == evento_id }).length;
  }

}
