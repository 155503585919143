import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '../../shared/logger';
import { ErrorMessages } from '../../shared/error/error-messages';
import { CheckListValue } from '../models';
import { QueryBuilder } from '../query';
import { Timestamp } from 'firebase/firestore';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

@Injectable()
export class ChecklistEquiposValuesService extends RestService<CheckListValue> {

    constructor(
        protected errorMessages: ErrorMessages,
        protected afAuth: AngularFireAuth,
        protected store: AngularFirestore,
        protected logger: Logger
    ) {
        super(errorMessages, afAuth, store, logger, 'checklist-equipos-values');
    }

    async hasChecklitsActive(checklistName: string) {
        const hoursAgo = Timestamp.fromDate(moment().subtract(24, 'hours').toDate())
        let builder = QueryBuilder.new();
        builder.limit(100);
        builder.where('agregado', '>=', hoursAgo);
        builder.orderBy('agregado', "desc")
        let options = builder.build();
        const checklist = await this.allWith(options).pipe(first()).toPromise();
        console.log(checklist)
        return checklist.filter(check => { return check.equipo === checklistName }).length > 0;
    }

}
