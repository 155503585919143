import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Platform } from '@ionic/angular';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

/**
 * Wrapper para nativo y mobile.
 */
@Injectable()
export class AnalyticsService {

  protected wrap: AngularFireAnalytics;
  isCap = false;

  constructor(
    protected platform: Platform,
    protected firebaseAnalytics: AngularFireAnalytics,
  ) {
    // this.wrap = this.platform.is('capacitor') ? nativeAnalytics : firebaseAnalytics;
    if (this.platform.is('capacitor')) {
      this.isCap = true;
    }
  }


  logEvent(name: string, params: any): void {
    // @ts-ignore
    if (this.isCap) {
      FirebaseAnalytics.logEvent({ name, params })
        .then(() => {})
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      this.firebaseAnalytics.logEvent(name, params)
        .then(() => {})
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  /**
   * Sets the user ID property.
   * This feature must be used in accordance with Google's Privacy Policy.
   * @param id The user ID
   * @return Returns a promise
   */
  setUserId(id: string): void {
    if (this.isCap) {
      FirebaseAnalytics.setUserId({ userId: id })
        .then(() => {
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      this.firebaseAnalytics.setUserId(id)
        .then(() => {
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  /**
   * Sets the current screen name, which specifies the current visual context in your app.
   * This helps identify the areas in your app where users spend their time and how they interact with your app.
   * @return Returns a promise
   */
  setCurrentScreen(name: string): void {
    if (this.isCap) {
      FirebaseAnalytics.setScreenName({ screenName: name })
        .then(() => {
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      this.firebaseAnalytics.setCurrentScreen(name)
        .then(() => {
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }
}
