import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '../../shared/logger';
import { ErrorMessages } from '../../shared/error/error-messages';
import { Pesada } from '../models';

@Injectable()
export class PesadaService extends RestService<Pesada> {

    providers: [
        { provide: 'ParentService', useClass: PesadaService } // O el servicio adecuado
      ]

    constructor(
        protected errorMessages: ErrorMessages,
        protected afAuth: AngularFireAuth,
        protected store: AngularFirestore,
        protected logger: Logger
    ) {
        super(errorMessages, afAuth, store, logger, 'pesadas');
    }

}
