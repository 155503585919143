export abstract class PAGES {
    static readonly LOGIN: Array<string> = ['login'];
    static readonly RESET_PASSWORD: Array<string> = ['reset-password'];
    static readonly REGISTER: Array<string> = ['register'];
    static readonly MENU: Array<string> = ['menu'];
    static readonly SUBIR_ARCHIVO: Array<string> = ['menu/administracion-archivos/subir-archivo'];
    static readonly SUBIR_ANUNCIO: Array<string> = ['menu/administracion-archivos/subir-anuncio'];
    static readonly PERSONAL_NUEVO: Array<string> = ['menu/personal-externo/personal-nuevo'];
    static readonly CONTENENDOR_DETALLE: Array<string> = ['menu/contenedores/contenedor-detalle'];
    static readonly CONTENENDOR_FOTO: Array<string> = ['menu/contenedores/foto'];    
    static readonly CONTENENDOR_ADD_EVENTO: Array<string> = ['menu/contenedores/agrega-evento'];    
    static readonly PESADA_DETALLE: Array<string> = ['menu/pesadas/pesada-detalle'];
    static readonly PESADA_FOTO: Array<string> = ['menu/pesadas/foto'];    
    static readonly PESADA_ADD_EVENTO: Array<string> = ['menu/pesadas/agrega-evento'];
    static readonly PARTE_DIARIO_DETALLE: Array<string> = ['menu/parte-diario/tabs/tab9'];
    static readonly PARTE_DIARIO_FIN_DETALLE: Array<string> = ['menu/parte-diario/tabs/tab10'];
}

export abstract class URLS {
    static readonly CONTENEDORES: string = 'contenedores';
    static readonly MESA_ENTRADA: string = 'mesa-entrada';
    static readonly PERSONAL_EXTERNO: string = 'personal-externo';
    static readonly PESADAS: string = 'pesadas';
    static readonly PARTE_DIARIO: string = 'parte-diario';
}

export abstract class MODULOS {
    static readonly CONTENEDORES: string = 'Contenedores';
    static readonly MESA_ENTRADA: string = 'Mesa de Entrada';
    static readonly PERSONAL_EXTERNO: string = 'Personal externo';
    static readonly PESADAS: string = 'Pesadas';
    static readonly PARTE_DIARIO: string = 'Parte Diario';
}

export const CONFIG = {
    FORMATS: {
        DATE: 'DD/MM/YYYY',
        DATE_TIME: 'DD/MM/YYYY HH:mm',
        TIME: 'HH:mm',
        DATE_FB: 'YYYY-MM-DD'
    }
};

export const EVENTOS_CONTENEDOR = {
    BULTOS: 'bultos',
    COMENTARIO: 'comentario',
    CONTROLKILOS: 'control-kilos',
    FIN: 'fin-contenedor',
    FOTOCONTBOLSAS: 'foto-cont-bolsas',
    FOTOPUERTASABIERTAS: 'foto-puertas-abiertas',
    FOTOPUERTASCERRADAS: 'foto-puertas-cerradas',
    FOTOPUERTASABIERTASCERRADAS: 'foto-puertas-abiertas-cerradas',
    FOTOLLENOPUERTASABIERTAS: 'foto-lleno-puertas-abiertas',
    FOTOVACIOPUERTASABIERTAS: 'foto-vacio-puertas-abiertas',
    FOTOMERCADERIAPISO: 'foto-mercaderia-piso',
    FOTOPLANILLA: 'foto-planilla',
    FOTOPRECINTO: 'foto-precinto',
    FOTO: 'foto',
    FUMIGADO: 'fumigado',
    INICIO: 'inicio-contenedor',
    ITEMTRAZABILIDAD: 'item-trazabilidad',
    LIBERACION: 'liberacion',
    FINPARCIAL: 'fin-parcial',
    PESADATRAZABILIDAD: 'pesada-trazabilidad',
    PRECINTOPROVISORIO: 'precinto-provisorio',
    INICIOPARCIAL: 'inicio-parcial',
    BOLSAS: 'bolsas',
    BOLSASVACIAS: 'bolsas-vacias',
    CODIGOTRAZABILIDAD: 'codigo-trazabilidad',
    PINCHAZOELEVADOR1: 'pinchazo-elevador-1-tcm',
    PINCHAZOELEVADOR2: 'pinchazo-elevador-2-heli',
    PINCHAZOELEVADOR3: 'pinchazo-elevador-3-5ton',
    PINCHAZOELEVADOR4: 'pinchazo-elevador-4-3ton',
    PINCHAZOELEVADOR5: 'pinchazo-elevador-5-5ton',
    PINCHAZOELEVADOR6: 'pinchazo-elevador-6-7ton',
    PINCHAZOSTACKERJGO: 'pinchazo-stacker-jgo',
    PINCHAZOSTACKERSTL: 'pinchazo-stacker-stl',
    PINCHAZOSTRADDLE: 'pinchazo-straddle',
    CAMBIOEQUIPO: 'cambio-equipo'
};

export const EVENTOS_PARTE_DIARIO = {
    CAMBIOEQUIPO: 'cambio-equipo',
    COMENTARIO: 'comentario',
    FIN: 'fin',
    INICIO: 'inicio',
};

export const EVENTOS_PESADAS = {
    BULTOS: 'bultos',
    INICIOCARGA: 'inicio-carga',
    COMENTARIO: 'comentario',
    INICIODESCARGA: 'inicio-descarga',
    FINCARGA: 'fin-carga',
    FINDESCARGA: 'fin-descarga',
    FOTOCAMIONLLENO: 'foto-camion-lleno',
    FOTOCAMIONVACIO: 'foto-camion-vacio',
    PINCHAZOCARGADESCARGA: 'pinchazo-carga-descarga',
    BOLSASVACIAS: 'bolsas-vacias',
    BOLSASROTAS: 'bolsas-rotas',
    BOOKING: 'booking',
    CODIGOTRAZABILIDAD: 'codigo-trazabilidad',
    FUMIGACIONPREVENTIVA: 'fumigacion-preventiva',
    NOCONFIRMAPRODUCTO: 'no-confirma-producto',
    CAMBIOEQUIPO: 'cambio-equipo'
};

export const BARCODESCAN = {
    preferFrontCamera: false, // iOS and Android
    showFlipCameraButton: false, // iOS and Android
    showTorchButton: true, // iOS and Android
    torchOn: false, // Android, launch with the torch switched on (if available)
    // saveHistory: true, // Android, save scan history (default false)
    prompt: "Coloque un código de barras en el interior\n del rectángulo del visor para escanear.", // Android
    resultDisplayDuration: 1500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
    formats: "QR_CODE", // default: all but PDF_417 and RSS_EXPANDED
    // orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
    // disableAnimations: true, // iOS
    disableSuccessBeep: false // iOS and Android
  }

export const GOOGLE_WEB_CLIENT_ID = '295061332117-hic0k3khqqfk4kvmrdkruhr3hkh59i5v.apps.googleusercontent.com';
export const API_JUANGO_URL = 'https://api.juango.com.uy/v2/index.php/api/';