import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '../../shared/logger';
import { ErrorMessages } from '../../shared/error/error-messages';
import { UsersLogin } from '../models';

@Injectable()
export class UsersLoginService extends RestService<UsersLogin> {

  constructor(
    protected errorMessages: ErrorMessages,
    protected afAuth: AngularFireAuth,
    protected store: AngularFirestore,
    protected logger: Logger
  ) {
    super(errorMessages, afAuth, store, logger, 'users-login');
  }

}