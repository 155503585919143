import { QueryConfig } from './api/rest.service';
import { WhereFilterOp } from 'firebase/firestore';

export class QueryBuilder {

    protected queryConfig: QueryConfig;

    static of(config: QueryConfig): QueryBuilder {
        return new QueryBuilder(config);
    }

    static new(): QueryBuilder {
        return new QueryBuilder();
    }

    constructor(queryConfig?: QueryConfig) {
        if (!queryConfig) {
            this.queryConfig = {
                field: undefined,
                limit: 10,
                reverse: true,
                prepend: undefined,
                lastValue: undefined,
                ignoreLastValue: true,
                dontListenChanges: false,
                whereClauses: [],
                like: undefined,
            };
        } else {
            this.queryConfig = queryConfig;
            if (!queryConfig.whereClauses) {
                queryConfig.whereClauses = [];
            }
        }
    }


    like(field: string,  value: any): QueryBuilder {
        this.queryConfig.like = {
            fieldPath: field,
            value
        };
        return this;
    }

    where(field: string, operator: WhereFilterOp, value: any): QueryBuilder {
        this.queryConfig.whereClauses.push({
            fieldPath: field,
            opStr: operator,
            value
        });
        return this;
    }

    orderBy(field: string, order: 'desc' | 'asc'): QueryBuilder {
        this.queryConfig.field = field;
        this.queryConfig.reverse = order === 'desc';
        return this;
    }

    limit(limit: number): QueryBuilder {
        this.queryConfig.limit = limit;
        return this;
    }

    reverse(reverse: boolean): QueryBuilder {
        this.queryConfig.reverse = reverse;
        return this;
    }

    prepend(prepend: boolean): QueryBuilder {
        this.queryConfig.prepend = prepend;
        return this;
    }

    lastValue(lastValue: any): QueryBuilder {
        this.queryConfig.lastValue = lastValue;
        return this;
    }

    listen(listenChanges: boolean): QueryBuilder {
        this.queryConfig.dontListenChanges = !listenChanges;
        return this;
    }

    ignoreLastValue(ignoreLastValue: boolean): QueryBuilder {
        this.queryConfig.ignoreLastValue = ignoreLastValue;
        return this;
    }

    build(): QueryConfig {
        return this.queryConfig;
    }

}
