import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Platform } from '@ionic/angular';
import { AuthService } from './../../core/api/auth.service';
import { UsersLoginService } from './../../core/api/users-login.service';
import { delay } from 'rxjs/operators';
import { SideEffect } from './effect.model';

import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { Timestamp } from 'firebase/firestore';


@Injectable({
  providedIn: 'root'
})
export class LoginDeviceEffect implements SideEffect {

  constructor(
    protected afAuth: AngularFireAuth,
    protected platform: Platform,
    protected usersLoginService: UsersLoginService,
    protected authService: AuthService) {

  }

  registerSideEffect(): void {
    this.afAuth.authState.pipe(
      // Pequeño delay para que la app se estabilize antes de mandar.
      delay(10000)
    ).subscribe(async state => {
      if (state && state.uid) {
        if (this.platform.is('capacitor')) {
          await this.sendDataMobile();
        } else {
          await this.sendDataBrowser();
        }
      }
    });
  }


  protected async sendDataMobile(): Promise<void> {
    const [user, info, uuidInfo, appInfo] = await Promise.all([
      this.authService.getUserAsync(),
      Device.getInfo(),
      Device.getId(),
      App.getInfo()
    ]);
    // Para evitar caso borde donde hay usuarios que no tienen perfil aun.
    if (user) {
      await this.usersLoginService.add({
        uid: user.uid,
        appBuild: appInfo?.build || null,
        appId: appInfo?.id || null,
        appName: appInfo?.name || null,
        appVersion: appInfo?.version,
        uuid: uuidInfo || null,
        created_at: Timestamp.now(),
        isChofer: user?.isChofer || null,
        name: info?.name || null,
        model: info?.model || null,
        platform: info?.platform || null,
        operatingSystem: info?.operatingSystem || null,
        osVersion: info?.osVersion || null,
        manufacturer: info?.manufacturer || null,
        isVirtual: info?.isVirtual || null,
        memUsed: info?.memUsed || null,
        diskFree: info?.diskFree || null,
        diskTotal: info?.diskTotal || null,
      }).toPromise();
    }
  }

  protected async sendDataBrowser(): Promise<void> {
    const user = await this.authService.getUserAsync();
    // Para evitar caso borde donde hay usuarios que no tienen perfil aun.
    if (user) {
      await this.usersLoginService.add({
        uid: user.uid,
        appBuild: null,
        appId: null,
        appName: navigator?.appName || null,
        appVersion: navigator?.appVersion || null,
        uuid: null,
        created_at: Timestamp.now(),
        isChofer: user?.isChofer || null,
        name: null,
        model: null,
        platform: 'web',
        operatingSystem: null,
        osVersion: null,
        manufacturer: null,
        isVirtual: null,
        memUsed: null,
        diskFree: null,
        diskTotal: null,
      }).toPromise();
    }
  };

}