import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '../../shared/logger';
import { ErrorMessages } from '../../shared/error/error-messages';
import { TypeEvent } from '../models';
import { QueryBuilder } from '../query';

import { AuthService } from './auth.service';
import { Timestamp } from 'firebase/firestore';

@Injectable()
export class EventsParteDiario extends RestService<TypeEvent> {

    user: any;

    constructor(
        protected errorMessages: ErrorMessages,
        protected afAuth: AngularFireAuth,
        protected store: AngularFirestore,
        protected logger: Logger,
        private authService: AuthService
    ) {
        super(errorMessages, afAuth, store, logger, 'events_parte_diario');
        this.authService.getUser()
            .subscribe(
                user => this.user = user
            );
    }

    getParentById(id_parent: string) {
        let builder = QueryBuilder.new();
        builder.orderBy('fecha', 'desc');
        builder.where('id_parent', '==', id_parent);
        builder.limit(100);
        let options = builder.build();
        return this.allWith(options)
    }

    async setDoc(doc: TypeEvent):Promise<TypeEvent> {
        doc.origen = 'parte_diario';
        if (!doc?.sin_equipo) {
            if (!doc.id_equipo && doc.parent.id_equipo)
                doc.id_equipo = doc.parent.id_equipo;
            if (!doc.equipo && doc.parent.equipo)
                doc.equipo = doc.parent.equipo;
        }
        doc.user = {
            uid: this.user.uid,
            displayName: this.user.displayName,
            email: this.user.email,
            photoURL: this.user.photoURL
        }
        doc.fecha = Timestamp.fromDate(new Date());
        return this.add(doc).toPromise();
    }

}
