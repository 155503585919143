import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '../../shared/logger';
import { ErrorMessages } from '../../shared/error/error-messages';
import { Empresa} from '../models';
import { QueryBuilder } from '../query';
import { first } from 'rxjs/operators';

export interface LocalEmpresa {
    nombre: string, 
    doc: Empresa
}

@Injectable()
export class EmpresasService extends RestService<Empresa> {
    empresas: Array<LocalEmpresa> = [];

    constructor(
        protected errorMessages: ErrorMessages,
        protected afAuth: AngularFireAuth,
        protected store: AngularFirestore,
        protected logger: Logger
    ) {
        super(errorMessages, afAuth, store, logger, 'empresas');
    }

    async validaConsolidado(empresa_nombre: string) {
        const empresa_filter = this.empresas.filter((e) => e.nombre == empresa_nombre);
        let empresa: Empresa;
        if (empresa_filter.length > 0) {
            // CACHE
            empresa = empresa_filter[0].doc;
        } else {
            // QUERY
            let builder = QueryBuilder.new();
            builder.where('nombre', '==', empresa_nombre);
            let options = builder.build();
            const docs = await this.allWith(options).pipe(first()).toPromise();
            if (docs.length > 0) {
                const doc = docs[0];
                empresa = doc;
                this.empresas.push({ nombre: empresa_nombre, doc: doc });
            } else {
                this.empresas.push({ nombre: empresa_nombre, doc: null });
                return true;
            }
        }
        if (empresa?.no_valida_consolidado)
            return false;
        else
            return true;
    }

}
