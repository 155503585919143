import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '../../shared/logger';
import { ErrorMessages } from '../../shared/error/error-messages';
import { Equipo, Responsable } from '../models';
import { Observable } from 'rxjs';
import { QueryBuilder } from '../query';
import { map } from 'rxjs/operators';

@Injectable()
export class EquiposService extends RestService<Equipo> {

    constructor(
        protected errorMessages: ErrorMessages,
        protected afAuth: AngularFireAuth,
        protected store: AngularFirestore,
        protected logger: Logger
    ) {
        super(errorMessages, afAuth, store, logger, 'equipos');
    }

    getEquipoByMatricula(matricula: string) {
        let builder = QueryBuilder.new();
        builder.where('matricula', '==', matricula);
        builder.limit(1);
        let options = builder.build();
        return this.allWith(options).pipe(
            map((res:any)=>{
               if (res && Array.isArray(res)) 
                  return res[0]   
               return res; 
            }))
    }

    getEquipos(): Observable<any> {
        let builder = QueryBuilder.new();
        builder.orderBy('matricula', 'asc');
        builder.where('habilitado', '==', 1);
        builder.limit(100);
        let options = builder.build();
        return this.allWith(options);
    }

}
