import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { URLS } from './../../app.constants';
import { ModulesRolesService } from '../../core/api/modules-roles.service';
import { ParteDiarioService } from '../../core/api/parte-diario.service';
import { AuthService } from '../../core/api/auth.service';
import { first } from 'rxjs/operators';
import { ParteDiario } from '../../core/models';


@Injectable({
    providedIn: 'root'
  })
export class PermissionsService {

    private module: string;
    private userPermissions = [];

    constructor(
        private route: ActivatedRoute,
        private modulesRolesService: ModulesRolesService,
        protected parteDiarioService: ParteDiarioService,
        protected authService: AuthService,
    ) {
    }

    async checkPermission(permissions:Array<string>,module?:string) {
        this.module = module ? module : this.route.snapshot['_routerState'].url.split('/')[2];

        /*
        Cuando el suario es maquinista en los modulos Contenedores / Pesada
        Solo tiene permiso de crear cuando existe un parte iniciado activo con equipo 
        */
        if(this.module == URLS.CONTENEDORES || this.module == URLS.PESADAS){
            const isMaquinista = await this.isMaquinista();
            if(isMaquinista){
                const currentUser = await this.authService.getUserAsync();
                const parteDiarioActivo = await this.parteDiarioService.getParteDiarioActivo(currentUser.uid).pipe(first()).toPromise() as ParteDiario;
                if(!parteDiarioActivo)
                    return false;
               // if(parteDiarioActivo.sin_equipo)
                 //   return false;
            }
        }

        this.userPermissions = await this.modulesRolesService.getUserPermission(this.module);
        let hasPermission = this.arrayContainsArray(this.userPermissions, permissions)
        return hasPermission;
    }

    arrayContainsArray(superset, subset) {
        if(!superset || !subset)
            return false;
        return subset.every((value) => {
            return (superset.indexOf(value) >= 0);
        });
    }

    async isMaquinista(){
        const permission_parte_diario = await this.modulesRolesService.getUserPermission(URLS.PARTE_DIARIO);
        if(permission_parte_diario){
            const isMaquinista = permission_parte_diario.indexOf('view') > -1 && permission_parte_diario.indexOf('create') > -1;
            return isMaquinista;
        }else{
            return false;
        }
    }


}