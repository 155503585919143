import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Platform } from '@ionic/angular';
import { from, NEVER, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { ErrorMessages } from '../../shared/error/error-messages';
import { Logger } from '../../shared/logger';
import { QueryBuilder } from '../query';
import { ApiError } from './rest.service';
import { UsersService } from './users.service';
import { Auth, signInWithPopup, GoogleAuthProvider, User, AuthProvider } from 'firebase/auth';
import { AnalyticsService } from './analytics.service';
import { CrashlyticsService } from './crashlytics.service';

@Injectable()
export class AuthService {

    user: Observable<User>;

    constructor(
        protected platform: Platform,
        protected errorMessages: ErrorMessages,
        protected afAuth: AngularFireAuth,
        protected usersService: UsersService,
        protected logger: Logger,
        protected analyticsService: AnalyticsService,
        protected crashlyticsService: CrashlyticsService
    ) {
        this.user = this.afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    if (user?.uid) {
                        try {
                          this.analyticsService.setUserId(user.uid);
                          this.crashlyticsService.setUserId(user.uid);
                        } catch (e) {
                          this.logger.error('Analytics Error');
                          this.logger.error(e);
                        }
                      }
                    return of(user);
                } else {
                    return NEVER;
                }
            }),
            shareReplay()
        );
    }

    async isUserLogged(): Promise<any> {
        return this.afAuth.user
            .pipe(take(1), map(user => !!user))
            .toPromise();
    }

    isUserLoggedIn(): Observable<boolean> {
        return this.getUser()
            .pipe(map(user => !!user));
    }

    getUser(): Observable<User> {
        return this.user;
    }

    getUserAsync(): Promise<any> {
        return this.getUser()
          .pipe(
            first()
          )
          .toPromise();
      }        

    registerUser(email: string, password: string): Observable<firebase.default.auth.UserCredential> {
        return from(this.afAuth.createUserWithEmailAndPassword(email, password))
            .pipe(catchError(err => throwError(this.mapError(err))));
    }

    loginUser(email: string, password: string): Observable<any> {
        return from(this.afAuth.signInWithEmailAndPassword(email, password))
            .pipe(catchError(err => throwError(this.mapError(err))));
    }

    resetPassword(email: string): Observable<any> {
        return from(this.afAuth.sendPasswordResetEmail(email))
            .pipe(catchError(err => throwError(this.mapError(err))));
    }

    async logout(): Promise<any> {
        return this.afAuth.signOut();
    }

    protected mapError(errorObj: any): void {
        const error = errorObj.error ? errorObj.error : errorObj;
        // Get error from errorMessages, if not found get the firebase error or a generic message if not defined.
        const message = error.code ? this.errorMessages.getMessage(error.code) : error.message || this.errorMessages.getGenericMessage();
        this.logger.error(error);
        throw new ApiError(error, message);
    }

    private oAuthLogin(provider: AuthProvider): Promise<firebase.default.auth.UserCredential> {
        return this.afAuth.signInWithPopup(provider);
    }

    getUserDoc(uid: string) {
        let builder = QueryBuilder.new();
        builder.where('uid', '==', uid);
        let options = builder.build();
        return this.usersService.allWith(options).pipe(take(1)).toPromise();
      }

}
