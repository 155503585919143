import { Injectable } from '@angular/core';
import { ForegroundService } from '@ionic-native/foreground-service/ngx';
import { Platform } from '@ionic/angular';

@Injectable()
export class ForegroundManagerService {

    private isStarted: boolean = false;

    constructor(
        private foregroundService: ForegroundService,
        private platform: Platform
    ) {
    }

    init() {
        if (this.platform.is('capacitor') && this.platform.is('android')) {
            if (!this.isStarted) {
                try {
                    this.foregroundService.start('Aplicación Juango', 'Se encuentra activa');
                    this.isStarted = true;
                } catch (error) {
                    console.log('ForegroundManagerService Error:', error)
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.platform.is('capacitor') && this.platform.is('android')) {
            this.foregroundService.stop();
            this.isStarted = false;
        }
    }

}
