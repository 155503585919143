import { AfterViewInit, Component, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Unsubscribe, User } from 'firebase/auth';
import { PAGES } from './app.constants';
import { LoginDeviceEffect } from './shared/effects/login-device.effect';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterEvent,
    Event
} from '@angular/router';

import { keyboardFix } from './shared/utils/utils';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { ForegroundManagerService } from './core/api/foreground-manager.service';
import { filter, Observable } from 'rxjs';



@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements AfterViewInit {

    timeout: any;
    loader: boolean = true;
    showLoader: boolean = true;

    constructor(
        private platform: Platform,
        private afAuth: AngularFireAuth,
        private router: Router,
        private ngZone: NgZone,
        private effectLogin: LoginDeviceEffect,
        private foregroundManagerService: ForegroundManagerService
    ) {

        router.events.pipe(
            filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)
        ).subscribe((e: RouterEvent) => {
            this.checkRouteChange(e);
        });

    }

    checkRouteChange(routerEvent: RouterEvent) {

        //console.log("router event");
        //console.log(routerEvent);

        // if route change started
        if (routerEvent instanceof NavigationStart) {

            this.showLoader = true;
            //console.log('this.showLoader:'  + this.showLoader);

        }
        // if route change ended
        if (routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel ||
            routerEvent instanceof NavigationError
        ) {
            setTimeout(() => {
                this.showLoader = false;
                //console.log('this.showLoader:'  + this.showLoader);
            }, 1000);
        }

    }

    initializeApp(): void {
        this.platform.ready()
            .then(() => {
                if (this.platform.is('capacitor')) {
                    Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
                        keyboardFix(true);
                    });
                    Keyboard.addListener('keyboardDidHide', () => {
                        keyboardFix(false);
                    });
                    setTimeout(() => {
                        // init foreground service
                        this.foregroundManagerService.init();
                      }, 5000);
                }
            });
    }

    ngAfterViewInit(): void {
        this.initializeApp();
        this.effectLogin.registerSideEffect();
        this.initializeFirebase();
    }

    initializeFirebase(): void {
        this.afAuth.authState.subscribe(async (user: User | null) => { // Actualiza el tipo de usuario
            if (!user) {
                await this.ngZone.run(async () => {
                    await this.router.navigate(PAGES.LOGIN); // Usa array para `navigate`
                });
            } else {
                await this.ngZone.run(async () => {
                    await this.router.navigate(PAGES.MENU); // Usa array para `navigate`
                });
            }
        });
    }
}
