import { FormArray, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Platform } from '@ionic/angular';
import { Timestamp } from 'firebase/firestore';
import { Photo } from '@capacitor/camera';

export function datePickerToMoment(date: any): moment.Moment {
    let result = moment();
    if (date.year && date.year.value) {
        result = result.year(date.year.value);
    }
    if (date.month && date.month.value) {
        result = result.month(date.month.value - 1);
    }
    if (date.day && date.day.value) {
        result = result.date(date.day.value);
    }
    if (date.hour && date.hour.value) {
        result = result.hour(date.hour.value);
    }
    if (date.minute && date.minute.value) {
        result = result.minutes(date.minute.value);
    }

    return result;
}

export function momentToDatePicker(momentDate: any): any {
    return {
        year: { value: momentDate.year() },
        month: { value: momentDate.month() + 1 },
        day: { value: momentDate.date() },
        hour: { value: momentDate.hour() },
        minute: { value: momentDate.minutes() }
    };
}

export function isDatePickerObject(date: any): boolean {
    if (date.year && date.year.value) {
        return true;
    }
    if (date.month && date.month.value) {
        return true;
    }
    if (date.day && date.day.value) {
        return true;
    }
    if (date.hour && date.hour.value) {
        return true;
    }
    if (date.minute && date.minute.value) {
        return true;
    }

    return false;
}

export function clone(object: any): any {
    return JSON.parse(JSON.stringify(object));
}

export abstract class ArrayUtils {

    static isNotEmpty(arr: Array<any>): boolean {
        return arr && arr.length > 0;
    }

    static isEmpty(arr: Array<any>): boolean {
        return !ArrayUtils.isNotEmpty(arr);
    }

}

/**
 * Esto se va cuando agreguen el plugin de g+
 */
export function isMobile(platform: Platform): boolean {
    return platform.is('cordova');
}

export function getRandomId(): string {
    return Math.random().toString(36).substring(2);
}

export function convertFileSrc(uri: string): string {
    // @ts-ignore
    return window.Ionic.WebView.convertFileSrc(uri);
}

export function normalizeURL(uri: string): string {
    return convertFileSrc(uri);
}

export const isUrl = (s: string) => {
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
};

export const polluteForm = (group: FormGroup | FormArray) => {
    for (const control in group.controls) {
        if (group.controls.hasOwnProperty(control)) {
            const formControl = group.controls[control];
            if (formControl instanceof FormGroup || formControl instanceof FormArray) {
                // If this is another form group inside, then recursively pollute form
                polluteForm(formControl);
            } else {
                formControl.markAsDirty();
                formControl.markAsTouched();
                formControl.updateValueAndValidity();
            }
        }
    }
};

/**
 * Retorna la diferencia de tiempo entre "data" y la fecha actual
 * @param data fecha a comparar
 * @returns obj ( obj.hours() , obj.days() , obj.minutes() )
 */
export const diffData = (start: any, end?: any): any => {
    return duration(diffDataMs(start, end));
}

/**
 * Retorna la diferencia de tiempo entre "data" y la fecha actual
 * @param data fecha a comparar
 * @returns miliseconds
 */
 export const diffDataMs = (start: any, end?: any): any => {
    let data1 = end ? moment.unix(end.seconds) : moment();
    let data2 = moment.unix(start.seconds);
    return data1.diff(data2);
}

/**
 * Retorna tiempo 
 * @param ms miliseconds
 * @returns obj ( obj.hours() , obj.days() , obj.minutes() )
 */
 export const duration = (ms: any): any => {
    return moment.duration(ms);
}

/**
 * Retorna una fecha en formato YYYY-MM-DD
 * @param days cantidad de dias atras
 * @returns string 2021-02-15
 */
 export const lastDays = (days:number): any => {
    const today = new Date();
    const lastD = new Date(today.getTime() - days * 24 * 60 * 60 * 1000);
    return moment(lastD).utcOffset('-0300').format('YYYY-MM-DD');
}

/**
 * Retorna una fecha 
 * @param days cantidad de dias atras
 * @param format 'YYYY-MM-DD'
 * @param date 'YYYY-MM-DD'
 * @returns string 2021-02-15
 */
 export const futureDate = (days:number, format:string, date?:Date): any => {
    const today = new Date();
    const lastD = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
    return moment(lastD).utcOffset('-0300').format(format);
}


/**
 * Retorna un date en el formato pasado como parametro 
 * @param date Date
 * @param formato String YYYY-MM-DD
 * @returns string 2021-02-15
 */
 export const formatData = (date:any, format:string): string => {
    return moment.unix(date.seconds).utcOffset('-0300').format(format);
}

/**
 * Retorna si es un timestamp valido
 * @param timestamp any
 * @returns boolean
 */
 export const isTimestamp = (timestamp:any): boolean => {
    var valid = (new Date(timestamp)).getTime() > 0;
    return valid;
}

/**
 * Retorna una fecha en formato ISO
 * @param timestamp any
 * @returns string 1997-07-16T19:20+01:00
 */
 export const timestampToIso = (timestamp:any): string => {
    if(timestamp && timestamp.seconds && isTimestamp(timestamp.seconds))
        return new Date(timestamp.seconds * 1000).toISOString();
    else
        return '';    
}

/**
 * Retorna un timestamp
 * @param isoDate string 1997-07-16T19:20+01:00
 * @returns timestamp any
 */
 export const isoToTimestamp = (isoDate:string): any => {
    if(moment(isoDate, moment.ISO_8601).isValid())
        return Timestamp.fromDate(new Date(isoDate));
    else
        return null;    
}

export const needsToUploadPhoto = (form: FormGroup): boolean => {
    //alert(form.get('image').value);
    let image = form.get('image').value;
    const type = typeof image;
    if (type === 'string') {
        return false;
    } else if (type === 'object') {
        image = image as Photo;
        return !!(image.webPath || image.dataUrl || image.base64String);
    }
    return false;
}   

export const keyboardFix = (open: boolean): void => {
    try {
        document.getElementsByTagName('ion-app')[0].style.height = open ? "101vh" : "auto";
    } catch (e) {
        console.warn('keyboardFix', e);
    }
}



