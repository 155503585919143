import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '../../shared/logger';
import { ErrorMessages } from '../../shared/error/error-messages';
import { ParteDiario } from '../models';
import { QueryBuilder } from '../query';
import { map } from 'rxjs/operators';

@Injectable()
export class ParteDiarioService extends RestService<ParteDiario> {

    constructor(
        protected errorMessages: ErrorMessages,
        protected afAuth: AngularFireAuth,
        protected store: AngularFirestore,
        protected logger: Logger
    ) {
        super(errorMessages, afAuth, store, logger, 'parte_diario');
    }

    getParteDiarioActivo(uid:string) {
        let builder = QueryBuilder.new();
        builder.where('user.uid', '==', uid);
        builder.where('activo', '==', true);
        builder.limit(1);
        return this.allWith(builder.build()).pipe(
          map((res: ParteDiario[]) => {
            if (res && Array.isArray(res))
              return res[0]
            return res;
          }
          ))
      }  

}
