import { Inject, Injectable } from '@angular/core';
import { RolesService } from './roles.service';
import { ModulesService } from './modules.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map, take } from 'rxjs/operators';
import { Modules } from '../../core/models';
import { Auth } from 'firebase/auth';
import { getIdTokenResult } from 'firebase/auth';

@Injectable()
export class ModulesRolesService {

  constructor(
    private rolesService: RolesService,
    private modulesService: ModulesService,
    private angularFireAuth: AngularFireAuth
  ) { }

  /* 
  * Promise retorna modules-roles del user autenticado 
  */
  cont: number = 0;
  modules_permissions: any = [];
  get(): Promise<any> {

    return new Promise((resolve, reject) => {

      if (this.modules_permissions.length > 0) 
        return resolve(this.modules_permissions);

      this.getUserRole().then((role) => {
        this.getModules().then((modules) => {
          this.getModulesPermissions(role).then((data) => {
            try {
              data.forEach((item: { module: Modules; }) => {
                item.module = modules.filter((module: { id: Modules; }) => {
                  return module.id === item.module
                })[0];
              });
              this.modules_permissions = data;
              resolve(data);
            } catch (e) {
              reject('Role \'' + role + '\' no existe')
            }
          })
        });
      });
    })
  }

  /* 
  * Promise retorna todos los modules  
  */
  getUrlList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get().then((modules_permissions) => {
        let ret = modules_permissions.map((item: { module: Modules; }) => { return item.module.url });
        resolve(ret);
      })
    });

  }

  /* 
  * Promise retorna todos los modules  
  */
  urlExist(url: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getUrlList().then((urls) => {
        let ret = urls.filter((item: string) => { return item.indexOf(url) > -1 }).lenght !== 0;
        resolve(ret);
      })
    });
  }

  /* 
  * Promise retorna todos los modules  
  */
  getModules(): Promise<any> {
    return this.modulesService.all()
      .pipe(take(1))
      .toPromise();
  }

  /* 
  * Promise retorna modules-permissions de un role especifico
  */
  getModulesPermissions(role: string): Promise<any> {
    return this.rolesService.find(role)
      .pipe(take(1), map(data => { return data['modules-permissions'] }))
      .toPromise();
  }

  /* 
  * Promise retorna el role del user autenticado 
  */
  getUserRole(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.authState.pipe(take(1)).subscribe(user => {
        if (user) {
          getIdTokenResult(user).then((token) => {
            if (!token.claims || !token.claims['role']) {
              reject('Usuario sin role asociado');
            }
            resolve(token.claims['role'] as string);
          });
        } else {
          reject('Usuario no autenticado');
        }
      });
    });
  }

  /* 
  * Promise retorna el role del user autenticado 
  */
  getUserPermission(moduleUrl: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get().then((modules_permissions) => {
        let permission = modules_permissions
          .filter((item: any) => { return item.module.url.indexOf(moduleUrl) > -1 })
          .map((item: any) => { return item.permission })[0];
        resolve(permission);
      })
    });

  }

}
