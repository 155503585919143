import { DeviceId } from "@capacitor/device";

export interface Entity {
    id?: string;
}

export interface UserProfile {
    uid: string;
    displayName: string;
    email: string;
    photoURL: string;
    maquinista?: boolean;
    parteDiario?: ParteDiario;
}

export interface Archivo extends StorageEntity {
    user: UserProfile;
    nombre: string;
    agregado: any;
    descripcion?: string;
    matricula_camion?: string;
}

export interface Anuncio extends Entity {
    booking: string;
    ctn: string;
    empresa: string;
    fecha: any;
    matricula: string;
    payload: string;
    precinto: string;
    nombre_producto: string;
    remito: string;
    numero_ctn: string;
    numero_booking: string;
    tara: string;
    uploadState: UploadState;
    tipo_operativa: TipoOperativa;
    fin_operativa:boolean;
    booking_proforma?:string,
    id_equipo?: string,
    equipo?: string,
    reserva?: string,
    reserva_nombre_producto?: string,
    reserva_empresa?: string,
}

export interface Pesada extends Entity {
    GRMovDoc: string;
    GRMovEmpDs: string;
    GRDstDsc: string;
    MatId: string;
    GRMovPrdDs: string;
    GRMovFch: string;
    GRMovHum: string;
    GRMovObs: string;
    calidad_cuerpos_ext: string;
    fecha?: any;
    tipo_operativa: TipoOperativa;
    fin_operativa: boolean;
    reserva?: string;
    id_equipo?: string,
    equipo?: string,
    events?:any
}

export interface EppsPrestado extends Entity {
    item: string;
}

export interface ArchivoAnuncio extends StorageEntity {
    descripcion?: string;
    matricula_camion?: string;
    asociado?: boolean;
    anuncio: Anuncio;
    id_anuncio: string;
    url?: string;
    agregado: any;
    user: UserProfile;
}

export enum UploadState {
    UPLOADED = 0,
    UPLOADING = 1,
    UPLOADING_FIRST = 2,
    ERROR = 3
}

export interface GoogleDriveFileInfo {
    name: string;
    id?: string;
    kind?: string;
    mimeType?: string;
}

export interface StorageEntity extends Entity {
    gdriveInfo?: GoogleDriveFileInfo;
    storageName?: string;
    storageNameThumb?: string;
    uploadState?: UploadState;
    url?: string;
    url_thumb?: string;
}


export interface Visita extends StorageEntity {
    cedula: number;
    nombre: string;
    nombre_lowercase: string;
    empresa: string;
    matricula: string;
    sector: string;
    responsable: string;
    state: VisitaState;
    entrada: any;
    salida?: any;
    epps_prestados?: Array<EppsPrestado>,
    observacion_entrada?:string,
    observacion?:string,
    temperatura:number,
    contacto_posible_positivo?: any;
    visita_exterior?: any;
    telefono: string;
    uploadState: UploadState;
}

export interface Empresa extends Entity  {
    no_valida_consolidado: boolean;
    nombre: string;
}

export enum VisitaState {
    FUERA = 0,
    DENTRO = 1,
}

export interface Proveedor extends Entity {
    nombre: string;
    nombre_lowercase: string;
    created_at?: string;
    hash?: string;
    razon_social?: string;
    rut?: string;
    telefono?: string;
    updated_at?: string;
}

export interface IngresoRestringido extends Entity {
    cedula: string;
    nombre?: string;
    descripcion?: string;
}

export interface Sector extends Entity {
    nombre: string;
}

export interface SectorMaquinas extends Entity {
    nombre: string;
}

export interface Reserva extends Entity {
    code: string;
    empresa: string;
    nombre_producto?: string;
    empresa_balanza?: string;
    booking_contenedores?: Array<any>;
    booking_numbers: Array<string>,
    movimiento: ReservaMovimiento;
    cancelada?: string;
    cierre_operaciones?: string;
    inicio: any;
    fin: any;
}

export enum ReservaMovimiento {
    ENTRADA = 'entrada',
    SALIDA = 'salida', 
    INTERNO = 'interno'
  }  

export interface Booking extends Entity {
    number: string;
    start_date: string;
    end_date: any;
    number_lowercase: any;
}

export interface Matriculas extends Entity {
    number: string;
    start_date: string;
    end_date: any;
    number_lowercase: any;
}

export interface Responsable extends Entity {
    nombre: string;
}

export interface Equipo extends Entity {
    matricula: string;
    nombre_tipo: string;
    modulos: Array<string>;
    tipo_events?: Array<string>;
}

export interface Fields extends Entity {
    title: string;
    value: string;
    selected: boolean;
    lowercase?: boolean;
}

export interface Modules extends Entity {
    icon: string,
    title: string,
    url: string
}

export interface ParteDiario extends Entity {
    user?: any,
    activo?: boolean,
    fecha_inicio?: any,
    fecha_fin?: any,
    tipo_operativa?: TipoOperativa, 
    sin_equipo?: boolean,
    equipo?:string,
    horimetro?: string,
    id_equipo?: string,
}

export interface Roles extends Entity {
    modules_permissions: Array<ModulesPermissions>
}

export interface ModulesPermissions {
    module: string,
    permission: Array<Permissions>
}

export interface Permissions {
    view: string,
    edit: string,
    delete: string
}

export interface Contenedor {
    id: string;
    added: string;
    fecha: string;
    matricula: string;
    idcliente: string;
    empresa: string;
    nombreproducto: string;
    tipo: string;
    booking:string;
    bookingnumero:string;
    numero_booking:string;
    numeroctn: string;
    nombre_producto:string;
    tara: string;
    payload: string;
    remito: string; 
    precinto: string;
    fumigado: string;
    consolidado: string;
    bultos: string;
    fechasalida: string;
    fechahora_pesada: string;
    matriculasalida: string;
    consolidadocantitems: string;
    consolidadofechafin: any;
    uploadState: UploadState;
  } 

  export interface radioValues{
      name:string;
      value:string;
  }

export interface InputType {
    name: string;
    field: string;
    type: string;
    required?: boolean;
    values?: Array<radioValues>
}

  export enum EventType {
    PRIMARY = 1,
    SECONDARY = 2,
    TERTIARY = 3,
  }

  export enum FormType {
    FORM = 0,      
    FLAG = 1,
    PHOTO = 2,
    PHOTO_OPTIONAL = 3,
    EXTERNAL_COMPONENT = 4
  }  

  export interface ContenedorInfo {
    name: string;
    info: string;
    icon: string;
  }

  export enum TypeOperator {
    CONDITION = 'condition',
    LOGICAL = 'logical_operator',
  }

  export interface Operator {
    type: string;
    count?: string;
    event_id?: string;
    after_event_id?: string;
    operator: TypeOperator;
  }

  export interface EventList{
      id:string;
      name?:string;
      color?:string;
      type:EventType;
      inputs?:Array<InputType>;
      conditions?: Array<Operator>;
      formType: FormType;
      example?:Example;
      selected?:boolean,
      component?: string,
  }

  export interface Example{
      img:string;
      text:string;
  }

  export interface TypeEvent extends Entity {
      id_parent: string;
      event: EventList;
      user?: UserProfile;
      fecha?: any;
      valor?: string;
      cantidad?: number;
      url?: string;
      path?: string;
      url_thumb?: string;
      storageName?:string;
      storageNameThumb?:string;
      path_thumb?: string;
      visionInfo?: string;
      gdriveInfo?:string;
      parent?: Anuncio | Pesada | ParteDiario;
      uploadState?: UploadState;
      origen?: string,
      reserva?: string,
      reserva_nombre_producto?: string,
      reserva_empresa?: string,
      id_equipo?: string,
      equipo?: string,
      tipo_operativa? : ReservaMovimiento
      sin_equipo?: boolean;
  }

  export interface ReservaTipoOperativa {
    reserva: string,
    tipo_operativa: string
  }

  export interface UserDoc extends Entity{
    uid: string;
    email: string;
    empresas?: Array<string>;
    role: string;
    marcaManual: boolean;
  }

  export enum TipoOperativa{
    EMBOLSADO = 'Consolidado embolsado', // Consolidado embolsado
    MANUAL = 'Trasbordo propio', // Tasbordo propio manual
    AWARD = 'Trasbordo award', // Tasbordo propio award
    TERCERO = 'Consolida tercero', // Consolida tercero
    GRANEL = "Consolidado granel", // Consolidado granel
    DESCONSOLIDADO = 'Desconsolidado', // Desconsolidado
    CARGA = 'Carga', // Carga
    DESCARGA = 'Descarga', // Descarga    
    PARTE_DIARIO = 'Parte diario', // Parte diario
    CTN_CARGADO = 'ctn-cargado', // Parte diario
    CTN_PISO = 'ctn-piso', // Parte diario
    PRE_ANUNCIO = 'pre-anuncio' // Pre anuncio
  }


  export interface UsersLogin extends Entity {
    name?: string;
    model: string;
    platform: 'ios' | 'android' | 'electron' | 'web';
    uuid: string | DeviceId;
    appVersion: string;
    appBuild: string;
    appId: string;
    appName: string;
    operatingSystem: 'ios' | 'android' | 'windows' | 'mac' | 'unknown';
    osVersion: string;
    manufacturer: string;
    isVirtual: boolean;
    memUsed?: number;
    diskFree?: number;
    diskTotal?: number;
    created_at?:any;
    uid: string;
    isChofer?:boolean;
  }

  /// Checklist equipos

  export interface CheckList extends Entity {
    input_id: string,
    prioridad: number,
    texto: string,
    tipo: CheckListTypes
  }

  export interface CheckListValue extends Entity {
    agregado: any;
    equipo: string
    user: UserDoc
  }

  export enum CheckListTypes {
    YES_NO = 1,
    QUALIFICATION = 2,
    COMMENT = 3,
  }
  