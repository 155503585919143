export const environment = {
    production: true,
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyDY8Vi-XcLYE8-6OjWYAY0TtRl4qdt811E",
        authDomain: "juango-planta.firebaseapp.com",
        databaseURL: "https://juango-planta.firebaseio.com",
        projectId: "juango-planta",
        storageBucket: "juango-planta.appspot.com",
        messagingSenderId: "295061332117",
        appId: "1:295061332117:web:d1dc0f8760fc7d4da37b9c",
        measurementId: "G-DYZS52PKWB"
    }

    /* FIREBASE_CONFIG: {
        apiKey: 'AIzaSyDfj3Af7XtCulFxpRBKjGX-sqKn9Ok_g1c',
        authDomain: 'juango-app-porteria-v2.firebaseapp.com',
        databaseURL: 'https://juango-app-porteria-v2.firebaseio.com',
        projectId: 'juango-app-porteria-v2',
        storageBucket: 'juango-app-porteria-v2.appspot.com',
        messagingSenderId: '205280595404'
    }*/
};
