import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Logger } from '../shared/logger';
import { ArchivoAnuncioService } from './api/archivo-anuncio.service';
import { AuthService } from './api/auth.service';
import { environment } from '../../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { ErrorMessages } from '../shared/error/error-messages';
import { ArchivoService } from './api/archivo.service';
import { AnuncioService } from './api/anuncio.service';
import { PesadaService } from './api/pesada.service';
import { ParteDiarioService } from './api/parte-diario.service';
import { StorageService } from './api/storage/storage.service';
import { VisitaService } from './api/visita.service';
import { ResponsablesService } from './api/responsables.service';
import { ReservasService } from './api/reservas.service';
import { SectoresService } from './api/sectores.service';
import { EquiposService } from './api/equipos.service';
import { ProveedoresService } from './api/proveedores.service';
import { IngresosRestringidosService } from './api/ingresos-restringidos.service';
import { RolesService } from './api/roles.service';
import { ModulosService } from './api/modulos.service';
import { ModulesService } from './api/modules.service';
import { ModulesRolesService } from './api/modules-roles.service';
import { EventContenedor } from './api/event-contenedor.service';
import { EventPesada } from './api/event-pesada.service';
import { EventParteDiario } from './api/event-parte-diario.service';
import { EppsPrestadosService } from './api/eeps-prestados.service';
import { EventsContenedores } from './api/events-contenedores.service';
import { EventsPesadas } from './api/events-pesadas.service';
import { EventsParteDiario } from './api/events-parte-diario.service';
import { BookingsService } from './api/bookings.service';
import { ChecklistEquiposService } from './api/checklist-equipos.service';
import { ChecklistEquiposValuesService } from './api/checklist-equipos-values.service';
import { MatriculasService } from './api/matriculas.service';
import { SectoresMaquinasService } from './api/sectores-maquinas.service';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { UsersService } from './api/users.service';
import { UsersLoginService } from './api/users-login.service';
import { File } from '@ionic-native/file/ngx';
import { EmpresasService } from './api/empresas.service';
import { AnalyticsService } from './api/analytics.service'
import { CrashlyticsService } from './api/crashlytics.service'
import { ForegroundManagerService } from "./api/foreground-manager.service";
import { BrowserModule } from '@angular/platform-browser';

/**
 * All services which have to have one and only one instance per application (singleton services) should be implemented here.
 * - Create a CoreModule with providers for the singleton services you load when the application starts.
 * - Import CoreModule in the root AppModule only. Never import CoreModule in any other module.
 * - Consider making CoreModule a pure services module with no declarations.
 */
@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        AngularFireModule.initializeApp(environment.FIREBASE_CONFIG), // Usa `initializeApp` para la configuración
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireAnalyticsModule
    ],
    declarations: [],
    providers: [
        AuthService,
        ArchivoService,
        AnuncioService,
        PesadaService,
        ParteDiarioService,
        ArchivoAnuncioService,
        StorageService,
        VisitaService,
        ResponsablesService,
        ReservasService,
        EquiposService,
        SectoresService,
        SectoresMaquinasService,
        ProveedoresService,
        IngresosRestringidosService,
        RolesService,
        ModulosService,
        ModulesService,
        ModulesRolesService,
        EventContenedor,
        EventPesada,
        EventsContenedores,
        EventParteDiario,
        EventsPesadas,
        EventsParteDiario,
        EppsPrestadosService,
        BookingsService,
        ChecklistEquiposService,
        ChecklistEquiposValuesService,
        MatriculasService,
        Logger,
        ErrorMessages,
        UsersService,
        BarcodeScanner,
        UsersLoginService,
        File,
        EmpresasService,
        AnalyticsService,
        CrashlyticsService,
        ForegroundManagerService
    ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {

    /* make sure CoreModule is imported only by one NgModule the AppModule */
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}
