import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

    constructor(protected storage: AngularFireStorage) {

    }

    async uploadFile(folder: string, fileName: string, arrayBuffer: any, metadata?: any): Promise<any> {
        metadata = metadata || {}; // Asegurarse de que metadata no sea undefined
        metadata.cacheControl = 'no-cache, no-store, max-age=0';
    
        return this.storage.ref(`${folder}/${fileName}`).put(arrayBuffer, metadata).then(snapshot => {
            return snapshot.ref.getDownloadURL();
        });
    }

}
